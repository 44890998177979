<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Annotazione su quotazione</span>
            <br>

            <hr>
        </div>
        <br><br>

        <div class="row justify-center">

            <div class="col-md-4 col-12 q-pr-md" align="left">
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Area:</q-item-label>
                            <q-item-label caption>{{quotazione.area}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{quotazione.id_prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.guid_preventivo}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.nominativo}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della quotazione:</q-item-label>
                            <q-item-label caption>{{quotazione.stato}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unit&agrave; Operativa:</q-item-label>
                            <q-item-label caption>{{quotazione.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Operatore:</q-item-label>
                            <q-item-label caption>{{quotazione.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="col-md-6 col-12 q-pr-md" align="left">
                <h6 class="q-mt-sm">Inserisci annotazione</h6>

                <q-input
                    outlined
                    square
                    v-model="annotazione"
                    filled
                    type="textarea"
                />
            </div>

        </div>
        <br><br>
        <hr>
        <div class="row" align="center">
            <div class="col-12 col-md-6" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-6" align="center">
                <QQButton label="REGISTRA ANNOTAZIONE" color="blue-grey" icon="mdi-content-save-outline" size="md" icon_align="right"
                    @click.native="onRegistraAnnotazione"
                />
            </div>
        </div>

        <br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy.js";
    import { mapFields} from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    //import store from "@/store";

    export default {
        name: "RegistraAnnotazione",
        data() {
            return {
                quotazione: {},
                annotazione: ""
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                formPreventivo: state => state.formPreventivo.formPreventivo
            }),
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                RegistraAnnotazioneSuQuotazione: "gestioneQuotazioni/RegistraAnnotazioneSuQuotazione"
            }),
            onIndietroClicked() {
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            },
            async onRegistraAnnotazione() {
                if (this.annotazione === "") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessuna nota inserita. Scrivi qualcosa prima di registrare'
                    });
                    return;
                }

                await this.RegistraAnnotazioneSuQuotazione(
                    {
                        guid_preventivo: this.quotazione.guid_preventivo,
                        note: this.annotazione
                    }
                );

                // Reindirizza sul menu di gestione della quotazione selezionata
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            },
            inCostruzione() {
                alert("IN COSTRUZIONE");
            }
        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
        }
    }
</script>
